var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left",class:_vm.inputSize},[_c('div',{staticClass:"flex justify-between"},[(_vm.label)?_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 mb-1",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]):_c('i'),_c('span',{staticClass:"flex items-center"},[(_vm.optional)?_c('span',{staticClass:"text-gray-500 text-sm",class:{ 'mr-2': _vm.tooltip }},[_vm._v("valgfri")]):_vm._e(),_vm._t("tooltip",function(){return [(_vm.tooltip)?_c('tooltip',{attrs:{"tabindex":-1,"content":_vm.tooltip,"placement":"top","effect":"dark","visible-arrow":true}},[_c('svg',{staticClass:"h-5 w-5 text-gray-600 tooltip",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z","clip-rule":"evenodd"}})])]):_vm._e()]})],2)]),_c('div',{staticClass:"relative rounded-md input-wrap flex items-center",class:[{ 'rounded-md': _vm.rounded}]},[_vm._t("input",function(){return [_c('input',_vm._g(_vm._b({ref:"input",staticClass:"block w-full sm:text-sm sm:leading-5 border focus:ring-2 focus:ring-opacity-50",class:[
          _vm.textColor,
          _vm.inputClasses,
          { 'rounded-md': _vm.rounded},
          { 'pr-10': (_vm.valid || _vm.invalid) && _vm.showValidationIcon },
          { 'bg-gray-200 cursor-not-allowed': _vm.disabled },
        ],attrs:{"id":_vm.id || _vm.label,"autocomplete":"none","disabled":_vm.disabled,"aria-invalid":true},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),(_vm.showValidationIcon)?_c('div',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[(_vm.invalid)?_c('span',{staticClass:"text-red-500"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e(),(_vm.valid)?_c('span',{staticClass:"text-green-500"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e()]):_vm._e()],2),_c('slide-y-up-transition',[(_vm.invalid)?_c('p',{staticClass:"text-sm text-red-600"},[_vm._t("helpBlock",function(){return [_vm._v(_vm._s(_vm.error))]})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }