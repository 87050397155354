<template>
  <div :class="inputSize" class="text-left">
    <div class="flex justify-between">
      <label
        v-if="label"
        :for="label"
        class="block text-sm font-medium leading-5 text-gray-700 mb-1"
      >
        {{ label }}
        <span v-if="required" class="text-red-500">*</span>
      </label>
      <i v-else></i>

      <span class="flex items-center">
        <span v-if="optional" :class="{ 'mr-2': tooltip }" class="text-gray-500 text-sm">valgfri</span>

        <!-- @slot substitute default tooltip -->
        <slot name="tooltip">
          <tooltip
            v-if="tooltip"
            :tabindex="-1"
            :content="tooltip"
            placement="top"
            effect="dark"
            :visible-arrow="true"
          >
            <svg class="h-5 w-5 text-gray-600 tooltip" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </tooltip>
        </slot>
      </span>
    </div>
    <div
      class="relative rounded-md input-wrap flex items-center"
      :class="[{ 'rounded-md': rounded}]"
    >
      <!-- @slot substitute the input -->
      <slot name="input" v-bind="slotData">
        <input
          :id="id || label"
          ref="input"
          v-bind="$attrs"
          :value="value"
          autocomplete="none"
          :disabled="disabled"
          :class="[
            textColor,
            inputClasses,
            { 'rounded-md': rounded},
            { 'pr-10': (valid || invalid) && showValidationIcon },
            { 'bg-gray-200 cursor-not-allowed': disabled },
          ]"
          class="block w-full sm:text-sm sm:leading-5 border focus:ring-2 focus:ring-opacity-50"
          :aria-invalid="true"
          v-on="listeners"
        />
      </slot>
      <div
        v-if="showValidationIcon"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <span v-if="invalid" class="text-red-500">
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>

        <span v-if="valid" class="text-green-500">
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
      </div>
    </div>

    <slide-y-up-transition>
      <p v-if="invalid" class="text-sm text-red-600">
        <!-- @slot custom validation errors -->
        <slot name="helpBlock">{{ error }}</slot>
      </p>
    </slide-y-up-transition>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import { inputSize } from '@/util/validator.props';

/**
 * @displayName BaseInput
 */
export default {
  name: 'BaseInput',
  components: {
    SlideYUpTransition,
  },
  inheritAttrs: false,
  props: {
    /**
     * @model
     */
    value: {
      type: [String, Number, Array, Object, Date],
      default: '',
    },
    /**
     * Whether input is required (adds an asterix *)
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether input is disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether an icon should be showed on validation
     */
    showValidationIcon: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether input is optoinal (appends optional hint to label)
     */
    optional: {
      type: Boolean,
      default: false,
    },
    /**
     * Size of input
     * @values sm | md | lg | xl | full
     */
    size: {
      type: String,
      default: 'full',
      validator: inputSize,
    },
    /**
     * Whether the input is valid and valid styles should appear
     */
    valid: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the input is invalid and invalid styles should appear
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Label to be displayed above input
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Tooltip text. Tooltip will be displayed if this has a value
     */
    tooltip: {
      type: String,
      default: '',
    },
    /**
     * Error to be displayed if input is invalid (overwrriten by helpBlock slot)
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * css classes to append to input
     */
    inputClasses: {
      type: String,
      default: '',
    },
    /**
     * whether the input is rounded
     */
    rounded: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    inputSize() {
      return {
        sm: 'max-w-sm',
        md: 'max-w-md',
        lg: 'max-w-lg',
        xl: 'max-w-xl',
        full: 'max-w-full',
      }[this.size || 'full'];
    },
    textColor() {
      if (this.valid) {
        return 'border-green-300 text-green-900 focus:border-green-500 focus:ring-green-600';
      }

      if (this.invalid) {
        return 'border-red-300 text-red-900 focus:border-red-500 focus:ring-red-600';
      }

      return 'border-gray-300 text-gray-900 focus:border-gray-500 focus:ring-gray-600';
    },
  },
  methods: {
    updateValue(evt) {
      const { value } = evt.target;
      /**
       * Fires when input recieves input
       */
      this.$emit('input', value);
    },
    onFocus(value) {
      this.focused = true;
      /**
       * Fires on focus
       */
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      /**
       * Fires when focus of input is lost
       */
      this.$emit('blur', value);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
