import ApiService from '@/common/api.service';

import queryBuilder from '@/util/queryBuilder';

const TaskService = {
  delete(id) {
    const resource = `tasks/${id}`;
    return ApiService.delete(resource);
  },

  update(payload) {
    const resource = 'tasks';
    return ApiService.put(resource, payload.id, payload);
  },

  getPopupContentData(taskIdList) {
    const resource = 'tasks/details/';
    return ApiService.post(resource, {
      tasks: taskIdList,
    });
  },

  export() {
    const resource = 'tasks/exports/';
    return ApiService.post(resource);
  },

  getPreviouslyMappedFrequencies() {
    const resource = 'tasks/frequencies/mappings/';
    return ApiService.get(resource);
  },

  getPreviouslyMappedPropertyZones() {
    const resource = 'tasks/zonecodes/mappings/';
    return ApiService.get(resource);
  },

  savePropertyZoneMappings(payload) {
    const resource = 'tasks/zonecodes/mappings/';
    return ApiService.post(resource, payload);
  },

  getTasksNotGeocoded() {
    const resource = 'tasks/geocodes';
    return ApiService.get(resource);
  },

  getDistinctTaskFrequencies() {
    const resource = 'tasks/frequencies/';
    return ApiService.get(resource);
  },

  getDistinctPropertyZones() {
    const resource = 'tasks/zonecodes/';
    return ApiService.get(resource);
  },

  getDistinctTaskPlannedWeekNumbers() {
    const resource = 'tasks/planned-week-no';
    return ApiService.get(resource);
  },

  saveOldPlannedWeekNumberMappings(payload) {
    const resource = 'tasks/planned-week-no/';
    return ApiService.post(resource, payload);
  },

  getDistinctTaskDisposalDays() {
    const resource = 'tasks/disposal-days';
    return ApiService.get(resource);
  },

  saveOldDisposalDayMappings(payload) {
    const resource = 'tasks/disposal-days/';
    return ApiService.post(resource, payload);
  },

  saveFrequencyMappings(payload) {
    const resource = 'tasks/frequencies/';
    return ApiService.post(resource, payload);
  },

  gecodeAllTasks({ geocode_method }) {
    const resource = 'tasks/geocodes/';
    return ApiService.post(resource, {
      geocode_method,
    });
  },

  removeBulkGeocoding(taskIds) {
    const resource = 'tasks/geocoding/bulk/';
    return ApiService.instance.post(resource, { task_ids: taskIds });
  },

  zonecodeAllTasks(overwriteAll) {
    const resource = 'tasks/zonecodes/';
    return ApiService.post(resource, {
      overwrite_all: overwriteAll,
    });
  },

  getGeocodeCountInCurrentMonth({ geocode_method }) {
    const resource = `tasks/geocodes/audits?geocode_method=${geocode_method}`;
    return ApiService.get(resource);
  },

  gecodeTasksManually(geocoded_tasks) {
    const resource = 'tasks/geocodes/manual';
    return ApiService.post(resource, { geocoded_tasks });
  },

  bulkUpdate({ queryParams, taskIds, isBulk, payload, updateType }) {
    const resource = 'tasks/bulk/';
    const resourceToQuery = queryBuilder(null, resource, queryParams);

    return ApiService.post(resourceToQuery, {
      tasks: taskIds,
      is_bulk: isBulk,
      disposal_day_type: updateType,
      ...payload,
    });
  },

  downloadTasks() {
    const resource = 'tasks/downloads';

    return ApiService.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },

  getCursor(link, params, taskIdList) {
    const resource = link || 'tasks/';
    const resourceToQuery = queryBuilder(link, resource, params);

    return ApiService.post(resourceToQuery, {
      tasks: taskIdList,
    });
  },

  bulkDelete({ queryParams, taskIds, isBulk }) {
    const resource = 'tasks/bulk/delete';
    const resourceToQuery = queryBuilder(null, resource, queryParams);
    return ApiService.post(resourceToQuery, {
      tasks: taskIds,
      is_bulk: isBulk,
    });
  },
};
export default TaskService;
