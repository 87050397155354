import ApiService from '@/common/api.service';

const VehicleService = {
  getAll() {
    const resource = 'vehicles/';
    return ApiService.get(resource);
  },

  getSelectOptions() {
    const resource = 'vehicles/?selectOptions=1';
    return ApiService.get(resource);
  },

  create(vehicle) {
    const resource = 'vehicles/';
    return ApiService.post(resource, vehicle);
  },

  update(payload) {
    const resource = 'vehicles/';
    return ApiService.put(resource, payload.id, payload);
  },

  delete(id) {
    const resource = `vehicles/${id}/`;
    return ApiService.delete(resource);
  },
};

export default VehicleService;
