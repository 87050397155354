var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"relative inline-flex flex-shrink-0 h-6 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",class:_vm.classes,staticStyle:{"width":"2.75rem"},attrs:{"role":"checkbox","tabindex":"0","aria-checked":"false"},on:{"click":function($event){_vm.model = !_vm.value}}},[_c('span',{staticClass:"translate-x-0 relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",class:[_vm.value ? 'translate-x-5' : 'translate-x-0'],attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:[
        _vm.value
          ? 'opacity-0 ease-out duration-100'
          : 'opacity-100 ease-in duration-200',
      ]},[_c('svg',{staticClass:"h-3 w-3 text-gray-400",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:[
        _vm.value
          ? 'opacity-100 ease-in duration-200'
          : 'opacity-0 ease-out duration-100',
      ]},[_c('svg',{staticClass:"h-3 w-3 text-indigo-600",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }