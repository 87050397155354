<template>
  <div class="text-sm font-medium bg-gray-800 text-white py-5 ring-1 ring-gray-900 ring-opacity-5">
    <div
      class="desktop-container mx-auto px-4 flex items-center sm:px-6 lg:px-8 space-x-5 sm:space-x-10"
    >
      <router-link
        v-if="!isInTransitionPeriod"
        id="Opsætning-navbar-button"
        :to="{ name: isLightWorkspace ? 'task-import-extra' : 'task-import' }"
        class="dark-link"
      >Opsætning</router-link>

      <router-link :to="{ name: isLightWorkspace ? 'map' : 'plan' }" id="Planlægning-navbar-button" class="dark-link" >Planlægning</router-link>

      <router-link :to="{ name: 'export' }" class="dark-link">Eksportering</router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheMainNav',
  computed: {
    ...mapGetters('departments', ['isInTransitionPeriod', 'isLightWorkspace']),
  },
};
</script>

<style>
a.dark-link.active-nav-link {
  @apply border-b border-indigo-500 text-indigo-50;
}
</style>
